import { render, staticRenderFns } from "./online-therapy-doctor-list.html?vue&type=template&id=526a7d0a&scoped=true&"
import script from "./online-therapy-doctor-list.js?vue&type=script&lang=js&"
export * from "./online-therapy-doctor-list.js?vue&type=script&lang=js&"
import style0 from "./online-therapy-doctor-list.scss?vue&type=style&index=0&id=526a7d0a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "526a7d0a",
  null
  
)

export default component.exports