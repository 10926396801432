/*eslint-disable */
const $ = window.jQuery = require('jquery');
import {bus} from '@/main';  
import {mapGetters } from 'vuex';
import axios from 'axios';
import carousel from 'vue-owl-carousel';
import countries from '@/services/country.json'
import root from '@/_helpers/roots';
let doctorFiteredUrl = root.doctorsListApiUrl.link;
import doctorInMailPopup from '@/components/private/doctor-in-mail-popup';
import beforeBookingMessageList from '@/components/private/before-booking-message-list';
import appointmentMakeFav from '@/components/private/doctor-make-favorite';
import makeUnfavouriteDoctor from '@/components/private/make-unfavourite-doctor';
import doctorViewFullInfo from '@/components/private/doctor-view-full-info';
export default {
  name: 'online-therapy-doctor-list',
  components: {
    carousel,
    'doctor-mail-popup' : doctorInMailPopup,
    'message-list':beforeBookingMessageList,
    'appointment-favourite' : appointmentMakeFav,
    'unfavourite-doctor-list' : makeUnfavouriteDoctor,
    'doctor-full-info' : doctorViewFullInfo
  },
  props: [],
  data () {
    return {
      doctorData:null,
      doctorDataResults:[],
      nextPageUrl:'',
      nextSteper:'',
      search_term:'',
      userLoading:false,
      sliderTouchDrag:false,
      countryList:[],
      disabledFilters:'',
      filterLoading:false,
      doctorDataResults:null,
      userInfo:null,
      directSmsForPopup:false,
      beforeBookingMessageList:null,
      beforeBookingMessageListState:false,
      appointmentInfoForFav:null,
      appointmentFavModal:false,
      unFavDoctorPopupState:false,
      doctorId:'',
      doctorFullInfoState:false,
      doctorFullInfoData:null
    }
  },
  computed: {
    ...mapGetters([
      'getOnlineTherapyDoctorsListGetters'
    ]),
    getAllDoctorsListFun(){
     this.doctorData = this.$store.getters.getOnlineTherapyDoctorsListGetters.data
     return;
    },
    SetIntialStatus() {
      if (this.doctorData) {
        this.nextPageUrl = this.doctorData.next
        this.nextSteper = this.doctorData.next
        this.doctorDataResults = this.doctorData.results
      }
      return;
    },
  },
  mounted () {
    bus.$on('doctorFullInfoStateBus', (data) => {
      this.doctorFullInfoState = data
    });
    bus.$on('unFavDoctorPopupStateBus', (data) => {
      this.unFavDoctorPopupState = data
      this.$store.dispatch('getDoctorsListApiFun')
    });
    bus.$on('appointmentFavModalBus', (data) => {
      this.appointmentFavModal = data
    });
    Object.keys(countries).map((key, index) => {
      this.countryList.push({
        name: countries[key].toLowerCase(),
        value: key.toLowerCase()
      })
    });
    this.countryList.sort((a, b) => (a.name > b.name) ? 1 : -1)
    this.getAllDoctorsListFun
    this.SetIntialStatus
    bus.$on('userUpdateStatus', (data) => {
      let user = this.doctorDataResults.find(item => item.id == data.userID);
      if(user) {
        user.is_online = data.is_online;
      }
    });
    $(document).ready(function(){
      $(".member_search_filter_label .form-control").click(function(){
        $('.member_search_filter_label').addClass("show_search")
      })
      $(document).click(function(event) {
        if (!$(event.target).closest(".member_search_filter_label").length) {
          this.isOpen = false;
          $('.member_search_filter_label').removeClass("show_search")
        }
      });
    })
    bus.$on('directSmsForPopupBus', (data) => {
      this.directSmsForPopup = data
    })
    bus.$on('beforeBookingMessageListStateBus', (data) => {
      this.beforeBookingMessageListState = data
    })
  },
  methods: {
   viewDoctorFullInfoMethod(data){
      document.body.classList.add('popup_open_body')
      this.doctorFullInfoData = data
      this.doctorFullInfoState = true
    },
    unFavouriteDoctorMethod(id){
      document.body.classList.add('popup_open_body')
      this.doctorId = id
      this.unFavDoctorPopupState = true
    },
    makeAppointmentFavouriteMethod(data){
      document.body.classList.add('popup_open_body')
      this.appointmentInfoForFav = data
      this.appointmentFavModal = true
    },
    beforeBookingMessageListMethod(data){
      document.body.classList.add('popup_open_body')
      this.beforeBookingMessageList = data
      this.beforeBookingMessageListState = true
    },
    directSmsForPopupOpenMethod(data){
      this.userInfo = data
      this.directSmsForPopup = true
      document.body.classList.add('popup_open_body')
    },
    filteredDoctorsList(param, doctorCount){
     if(doctorCount > 0){
     if(this.disabledFilters == '' || this.disabledFilters != param){
       this.filterLoading = true
        axios.get(doctorFiteredUrl + '?filter_type=' + param).then((response) => {
          this.doctorDataResults = []
          this.doctorDataResults = response.data.data.data.results
          this.nextPageUrl = response.data.data.data.next
          this.nextSteper = response.data.data.data.next
          this.filterLoading = false
         $('.total_count_box').removeClass('active')
         $('#filtered' + param).addClass('active')
         this.disabledFilters = param
       }).catch((err) => {
          this.filterLoading = false
       })
     }else{
       this.filterLoading = true
        axios.get(doctorFiteredUrl).then((response) => {
          this.doctorDataResults = []
          this.doctorDataResults = response.data.data.data.results
          this.nextPageUrl = response.data.data.data.next
          this.nextSteper = response.data.data.data.next
          this.filterLoading = false
         $('.total_count_box').removeClass('active')
         this.disabledFilters = ''
       }).catch((err) => {
          this.filterLoading = false
       })
     }}
   },
    getCountryFlag: function(countryCode, type) {
      if (countryCode == null) {
        countryCode = ''
      }
      let countryName = countryCode.toLowerCase()
      let country = this.countryList.filter(item => (item.value) == (countryName) || (item.name) == (countryName));
      if (country && country.length > 0) {
        country = country[0]
        if (type == 'flag') {
          country = `https://567c9b4f06e54c9c9229fe11191a6432.s3.ca-central-1.amazonaws.com/media/shiny/${country.value}.png`
        } else {
          country = country.name
        }
      } else {
        country = ''
      }
      return country;
    },
    validateSearchDoctorBeforeSubmit(){
      this.$validator.validateAll().then((result) => {
        if (result) {
          if(this.search_term.length >= 3){
            this.$store.dispatch('doctorUserSearchFun', { search_term: this.search_term }).then((response) => {
              this.doctorDataResults = []
              this.doctorDataResults = response.data.data.data.results
              this.nextPageUrl = response.data.data.data.next
              this.nextSteper = response.data.data.data.next
            })
          }else if(this.search_term.length == 0){
            this.$store.dispatch('getDoctorsListApiFun').then((response) => {
              this.doctorDataResults = []
              this.doctorDataResults = response.data.data.data.results
              this.nextPageUrl = response.data.data.data.next
              this.nextSteper = response.data.data.data.next
            }).catch((err) => {
            })
          }
        }
      })
    },
    showLoadMoreDataFun(url) {
      this.userLoading = true
      axios.get(url).then((response) => {
          this.loadmoreData = response.data.data
          this.nextSteper = this.loadmoreData.data.next
          this.$set(this.doctorDataResults, 'next', this.nextSteper)
          if (this.loadmoreData) {
            for (var i = 0; i < this.loadmoreData.data.results.length; i++) {
              this.doctorDataResults.push(this.loadmoreData.data.results[i]);
              this.userLoading = false
            }
          }
        });
    },
    handleScroll() {
      if ((document.documentElement.scrollTop + window.innerHeight) >= document.documentElement.offsetHeight) {
        if(this.nextPageUrl){
          this.showLoadMoreDataFun(this.nextPageUrl);
        }
      }
    }
  },
  watch:{
    nextSteper(old_val, new_val){
      if(new_val != ''){
        this.nextPageUrl = new_val
      }
    },
    getAllDoctorsListFun(){
      return this.$store.getters.getOnlineTherapyDoctorsListGetters
    },
    SetIntialStatus() {
      if (this.doctorData) {
        this.nextPageUrl = this.doctorData.next
        this.doctorDataResults = this.doctorData.results
      }
      return;
    },
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
}